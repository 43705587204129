import { render, staticRenderFns } from "./DashboardSettingsDrawer.vue?vue&type=template&id=961100ee&scoped=true"
import script from "./DashboardSettingsDrawer.vue?vue&type=script&lang=js"
export * from "./DashboardSettingsDrawer.vue?vue&type=script&lang=js"
import style0 from "./DashboardSettingsDrawer.vue?vue&type=style&index=0&id=961100ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "961100ee",
  null
  
)

export default component.exports