// 公共
const tools = {
	create: {
		url: "/addons/chatgpt/image_fix/create",
		method: "POST",
		desc: "发送图片"
	},
	getTask: {
		url: "/addons/chatgpt/image_fix/getTask",
		method: "POST",
		desc: "获取工具返回结果"
	},
	mp4toMp3: {
		url: "/addons/chatgpt/video_fix/mp4toMp3",
		method: "POST",
		desc: "mp4转mp3"
	},
	compression: {
		url: "/addons/chatgpt/video_fix/compression",
		method: "POST",
		desc: "视频工具压缩"
	},
	audio2txt: {
		url: "/addons/chatgpt/video_fix/audio2txt",
		method: "POST",
		desc: "音频转换为文字"
	},
	video2txt: {
		url: "/addons/chatgpt/video_fix/video2txt",
		method: "POST",
		desc: "视频转换为文字"
	},
	config: {
		url: "/addons/chatgpt/image_fix/config",
		method: "POST",
		desc: "工具列表"
	},
	views: {
		url: "/addons/chatgpt/image_fix/views",
		method: "POST",
		desc: "查看工具views"
	},
	

	
}

export default tools
