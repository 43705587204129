// 全局变量
let BASE_API = ""
if (process.env.NODE_ENV == "development") {
	BASE_API = "http://www.aihelp.com"
	// BASE_API = window.location.origin
} else if (process.env.NODE_ENV == "production") {
	BASE_API = window.location.origin
} else if (process.env.NODE_ENV == "test") {//自定义test环境 常用这个
	// BASE_API = 'https://ai.nymaite.cn' 
	BASE_API = 'https://aitest.nymaite.com'
}

export { BASE_API }
